import { capFirstWordFirstLetter, capSentenceFirstLetter } from '@/util/CISUI';
import { Input, Form } from 'antd';
import { useIntl } from 'react-intl';

const { TextArea: AntTextarea } = Input;

export default function InputTextArea({
  label = '',
  children,
  required = false,
  placeholder = "",
  ...props
}) {
  const intl = useIntl();
  return (
    <Form.Item
      name={props.name}
      rules={[
        {
          required: required,
          message: `Please enter ${label}`,
        },
      ]}
      label={label ? intl.formatMessage({
        id: capSentenceFirstLetter(label),
      }) : ""}
    >
      <AntTextarea
        {...props}
        className={`${props.className}`}
        placeholder={placeholder ? intl.formatMessage({
          id: capFirstWordFirstLetter(placeholder || ''),
        }) : ""}
      >
        {children}
      </AntTextarea>
    </Form.Item>
  );
}
