import React, { useState } from 'react';
import { Form, Input, Tooltip, Progress, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const InputPassword = ({required = true, disabled = false, name= "password", label = "Password", placeholder = "Enter password"}) => {
    const [form] = Form.useForm();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [strength, setStrength] = useState(0);

    const checkPasswordStrength = (value) => {
        let strengthValue = 0;
        if (value.length >= 8) strengthValue += 25; // Length check
        if (/[A-Z]/.test(value)) strengthValue += 25; // Uppercase check
        if (/[a-z]/.test(value)) strengthValue += 25; // Lowercase check
        if (/\d/.test(value)) strengthValue += 25; // Number check
        if (/[@$!%*?&#]/.test(value)) strengthValue += 25; // Special character check

        setStrength(strengthValue);
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        checkPasswordStrength(value);
        form.validateFields(['confirm']);
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        form.validateFields(['confirm']);
    };

    const validateConfirmPassword = (_, value) => {
        if (!value || value === password) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('The two passwords do not match!'));
    };

    return (<>
            <Form.Item
                label={label}
                tooltip={{
                    title: 'Password must be at least 8 characters long, include uppercase, lowercase, numbers, and special characters.',
                    icon: <QuestionCircleOutlined />,
                }}
                name={name}
                rules={[{ required: required, message: 'Please input your password!' }]}
            >
                <Input.Password value={password} onChange={handlePasswordChange} />
            </Form.Item>

            <Progress
                percent={strength}
                showInfo={false}
                strokeColor={{
                    '0%': '#ff4d4f',
                    '100%': '#87d068',
                }}
            />

            <Form.Item
                label="Confirm Password"
                name="confirm_password"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: required,
                        message: 'Please confirm your password!',
                    },
                    {
                        validator: validateConfirmPassword,
                    },
                ]}
            >
                <Input.Password value={confirmPassword} onChange={handleConfirmPasswordChange} />
            </Form.Item>
        </>
    );
};

export default InputPassword;
