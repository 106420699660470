import { Button, Col, Form, Image, Input, message, Row } from "antd";
import { Link, useHistory } from "react-router-dom";

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import AxiosInstance from "../util/Api";
import { useMutation } from "react-query";
import CircularProgress from "../components/CircularProgress";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import img from "../assets/images/login.png";
import { useDispatch } from "react-redux";
import { setAuthData } from "@/appRedux/actions";
import {ConnectionError} from "@/util/CISUI";

const SignInV3 = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [quotation, setQuotation] = useState(0);
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

    const quotes = [
        "Success is not the key to happiness. Happiness is the key to success.",
        "The best time to plant a tree was 20 years ago. The second best time is now.",
        "Your time is limited, so don’t waste it living someone else’s life.",
        "Don’t watch the clock; do what it does. Keep going.",
        "Hardships often prepare ordinary people for an extraordinary destiny."
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        }, 3000); // Change quote every 3 seconds
        return () => clearInterval(interval);
    }, [quotes.length]);

    useEffect(() => {
        if (
            secureLocalStorage.getItem("token") &&
            secureLocalStorage.getItem("userData") &&
            secureLocalStorage.getItem("permissions")
        ) {
            history.push("/dashboard");
            dispatch(
                setAuthData({
                    user: secureLocalStorage.getItem("userData"),
                    token: secureLocalStorage.getItem("token"),
                    permissions: secureLocalStorage.getItem("permissions"),
                })
            );
        }
    }, []);

    const singin = async (values) => {
        const { data: response } = await AxiosInstance.post("auth/login", values);
        return response;
    };

    const { mutate, isLoading } = useMutation(singin, {
        onSuccess: (data) => {
            if (data.status === 1) {
                message.success(data.msg);
                secureLocalStorage.setItem("token", data.token);
                secureLocalStorage.setItem("userData", data.user);
                secureLocalStorage.setItem("permissions", data.prms);
                history.push("/dashboard");
                dispatch(
                    setAuthData({
                        user: data.user,
                        token: data.token,
                        permissions: data.prms,
                    })
                );
            } else if (data.status === 0) {
                message.warning(data.error);
            } else {
                message.error(data.msg);
            }
        },
        onError: () => {
            message.error(ConnectionError());
        },
    });

    const onFinish = (values) => {
        console.log("🚀 ~ file: SignIn.js:52 ~ onFinish ~ values:", values);
        mutate(values);
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Row className="signin" style={{ margin: "0" }}>


            <Col xs={0} lg={6} className="signin__left">
                <div className="login_left_logo_top">
                    <img src="/assets/images/eChurch_icon.png" alt="eChurch" className="login_left_logo" />
                </div>

                <div className="login_left_bar_center_item">
                    “Unleashed to Live a Life Worthy of Your Calling,”
                    <div style={{fontSize: '15px', fontWeight: "normal", color: 'grey'}}>
                        Ephesians 4:1, 1 Thessalonians 4:7, and Galatians 1:24
                    </div>
                </div>

                <div className="login_left_bar">
                    Reaching Everyone for Jesus!
                </div>

                <div className="copyrights">
                    © 2024 The Church of Pentecost – eChurch Global.
                    <br />All Rights Reserved
                </div>

            </Col>

            <Col
                xs={24}
                lg={18}

                className="signin__right"
            >
                <div style={{ width: '400px', margin: "auto" }} className="login_box" >
                    <div>
                        <img src="/assets/images/echurch_logo.png" alt="eChurch" className="logo" />
                    </div>
                    <div className="signin__right__title">
                        {/*<p>Sign in to your account to continue.</p>*/}
                    </div>
                    <div className="loginBoxItem">
                        <Form
                            className="signin__right__form"
                            onFinish={onFinish}
                            layout="vertical" >
                            <Form.Item
                                name="userid"
                                label="Username or Email"
                                rules={[{ required: true, message: "This field is required" }]}
                                className="singin--input"
                            >
                                <Input
                                    placeholder="Username"
                                    prefix={<UserOutlined />}
                                    required={true}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ required: true, message: "Password is required!" }]}
                                className="singin--input"
                            >
                                <Input.Password
                                    placeholder="Password"
                                    prefix={<LockOutlined />}
                                    required={true}
                                    size="large"
                                />
                            </Form.Item>

                            <Form.Item className="forgot__password">
                                <Link to="/forgot-password">Forgot password</Link>
                            </Form.Item>

                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    className="signin__right__form__submit tw-h-12"
                                    size="large"
                                    loading={isLoading}
                                >
                                    Login
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>

                <div className="login_right_bar">
                    <div className="quote-scroller">
                        <p className="quote-text">A Ministry of the Church of Pentecost</p>
                    </div>
                </div>

            </Col>

        </Row>
    );
};

export default SignInV3;
